import React from 'react'
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import "./404/404.scss"
import { posix } from 'path';
import { IContact } from '../../../server/src/services/entities/contacts/contactsManager';

export default class EventCategoriesManagement extends React.Component {
    alreadySent = false;

    async runTest() {
        if (this.alreadySent) return;
        this.alreadySent = true;
        
        let res = await fetch('api/tests')
        let map = await res.json();
        console.log(map);
        // this.downloadJSON(map)
    }

    downloadJSON(data: any) {
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
        let dlAnchorElem = document.createElement("a");
        dlAnchorElem.setAttribute("href",     dataStr     );
        dlAnchorElem.setAttribute("download", "scene.json");
        document.body.appendChild(dlAnchorElem); // Required for FF
        dlAnchorElem.click();
    }

    downloadCSV(map: { [email:string]:IContact[] }) {
        const rows: any[][] = []
        rows.push(['id', 'email', 'phone', 'first_name', 'last_name'])
        for (const email in map) {
            map[email].forEach((c: IContact) => {
                rows.push([c.id, email, c.phone_number, c.first_name, c.last_name])
            })
        }

        let csvContent = "data:text/csv;charset=utf-8," 
            + rows.map(e => e.join(",")).join("\n");

        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "phoneDuplivatesData.csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
    }


    render() {
        if (window.location.pathname === '/tests') this.runTest()

        return (
            <div className={"page-wrap-event-categories-management"}>
                <TopNavBar>
                    <div className="title-wrap">
                        <span className="title-lang">העמוד לא נמצא</span>
                    </div>
                </TopNavBar>
                <div className={"not-found-message"}>
                    <div style={{fontWeight: "bold"}}>404</div>
                    <div>העמוד לא קיים במערכת</div>
                </div>
            </div>
        )
    }
}