import React from 'react';
import {ISchemaField, ISchemaFieldType} from "../../UIComponents/JSONSchemaEditor/JSONSchemaEditor";
import server from "../../services/server";
import "./ContactsFieldsMatcher.scss"
import Select, {components, StylesConfig } from 'react-select';
import { IContactFields } from '../ImportContacts';

interface IProps {
    contactsData: string[][],
    setContacts: (contacts:{[key:string]: any}[], fields: IContactFields[])=>any
}

interface IState {
    useFirstRowAsTitle: boolean
    availableFields: IContactFields[],
    matching: {[key: number]:(number | null | undefined)}
}

const baseFields = [
    {
        title: "שם פרטי",
        field: "first_name",
        defaultValue: "",
        schema: {
            isRoot: true, fieldKey:"", fieldTitle:"", fieldType:ISchemaFieldType[ISchemaFieldType.stringField],
            isArray:false, isObject:false, isValid:true, properties: [], disableEdits: false, lockEdits: true
        },
        baseField: true
    },
    {
        title: "שם משפחה",
        field: "last_name",
        defaultValue: "",
        schema: {
            isRoot: true, fieldKey:"", fieldTitle:"", fieldType:ISchemaFieldType[ISchemaFieldType.stringField],
            isArray:false, isObject:false, isValid:true, properties: [], disableEdits: false, lockEdits: true
        },
        baseField: true
    },
    {
        title: "מספר טלפון",
        field: "phone_number",
        defaultValue: "",
        schema: {
            isRoot: true, fieldKey:"", fieldTitle:"", fieldType:ISchemaFieldType[ISchemaFieldType.stringField],
            isArray:false, isObject:false, isValid:true, properties: [], disableEdits: false, lockEdits: true
        },
        baseField: true
    },
    {
        title: "כתובת אימייל",
        field: "email_address",
        defaultValue: "",
        schema: {
            isRoot: true, fieldKey:"", fieldTitle:"", fieldType:ISchemaFieldType[ISchemaFieldType.stringField],
            isArray:false, isObject:false, isValid:true, properties: [], disableEdits: false, lockEdits: true
        },
        baseField: true
    },
    {
        title: "יישוב מגורים",
        field: "residency",
        defaultValue: "",
        schema: {
            isRoot: true, fieldKey:"", fieldTitle:"", fieldType:ISchemaFieldType[ISchemaFieldType.stringField],
            isArray:false, isObject:false, isValid:true, properties: [], disableEdits: false, lockEdits: true
        },
        baseField: true
    },
    {
        title: "סטטוס חברות",
        field: "membership_status",
        defaultValue: "",
        schema: {
            isRoot: true, fieldKey:"", fieldTitle:"", fieldType:ISchemaFieldType[ISchemaFieldType.stringField],
            isArray:false, isObject:false, isValid:true, properties: [], disableEdits: false, lockEdits: true
        },
        baseField: false
    },
]

export interface ColourOption {
    readonly value: string;
    readonly label: string;
    readonly color: string;
    readonly unmatched?: boolean;
    readonly isFixed?: boolean;
    readonly isDisabled?: boolean;
}


export interface GroupedOption {
    readonly label: string;
    readonly options: readonly ColourOption[];
}

export default class ContactsFieldsMatcher extends React.Component <IProps, IState>  {
    constructor(props:IProps) {
        super(props);
        this.state = {
            useFirstRowAsTitle: true,
            availableFields: baseFields,
            matching: {
                0: 0, 1: 1, 2: 2, 3: 3, 4: 4
            }
        };
    }

    async componentDidMount() {
        await this.getContactFields();

        let firstColumnName = this.props.contactsData.slice()[0][0];
        if (firstColumnName.toLowerCase() === 'timestamp') this.setState(
            { matching: {
                1: 0, 2: 1, 3: 2, 4: 3, 5: 4
            }}
        )
    }

    getContactFields = async () => {
        const data = await server.get('customContactFields');
        if(Array.isArray(data)){
            const customFields = data.map(f => {
                return {
                    title: f.title,
                    field: f.field_key,
                    defaultValue: "",
                    schema: f.field_type,
                    baseField: false
                }
            });
            const availableFields = baseFields.concat(customFields);
            this.setState({availableFields})
        }
    };

    UNMATCHED_COL_VAL = "unmatched";

    setMatch = (i: number, value: string) => {
        const matching = this.state.matching;
        const setTo = (value === this.UNMATCHED_COL_VAL) ? null : parseInt(value);
        if(setTo !== null) {
            // unset other columns pointing at this field
            Object.keys(matching).map(Number).forEach(p => {
                if (matching[p] === setTo && p !== i) {
                    matching[p] = null;
                }
            })
        }
        matching[i] = setTo;
        this.setState({matching})
    }

    produceContacts = () => {
        const contactTable = this.props.contactsData.slice();
        if(contactTable.length - (this.state.useFirstRowAsTitle ? 1 : 0) < 1){
            return;
        }
        const contactData = contactTable.filter((d, i) => i !== 0 || !this.state.useFirstRowAsTitle);
        const matching = this.state.matching;
        const fields = this.state.availableFields;
        const usedFieldsIndices:{[key: number]: boolean} = {};
        const cols = this.props.contactsData[0].slice();
        const contacts = contactData.map((d, i) => {
            const contact:{[key:string]: any} = {};
            contact["custom_fields"] = {};
            cols.forEach((c, j)=>{
                const fieldIndex = matching[j];
                if(fieldIndex || fieldIndex === 0) {
                    usedFieldsIndices[fieldIndex] = true;
                }
                if(fieldIndex === null || fieldIndex === undefined){
                    return;
                }
                const field = fields[fieldIndex];
                if(field.baseField) {
                    contact[field.field] = d[j];
                }
                else {
                    let value = d[j] as any
                    if (field.schema.fieldType === 'booleanField') {
                        if (value.trim() === 'כן') value = true
                        else value = false
                    }
                    contact["custom_fields"][field.field] = value;
                }
            })
            return contact;
        })
        const usedFields = fields.filter((f, j)=>usedFieldsIndices[j]);
        this.props.setContacts(contacts, usedFields);
    }

    render() {
        const contactData = this.props.contactsData.slice();
        const useFirstRowAsTitle = this.state.useFirstRowAsTitle;
        const emptyFile = <div>בקובץ שנבחר לא קיימות רשומות</div>
        const matching = this.state.matching
        const availableFields = this.state.availableFields.slice();

        return (
            <div>
                {contactData.length ?
                    <div className={"field-matcher"}>
                        {contactData[0].map((c, i)=>{
                            const colMatch = matching[i];
                            return <div
                                key={i} className={"field-col-wrap"}
                            >
                                <div className={"file-header-field import-matcher-field-wrap"}>
                                    <span>{useFirstRowAsTitle ? c : "עמודה " + (i+1)}</span>
                                </div>
                                <div
                                    className={"field-selector-wrap import-matcher-field-wrap"}
                                >
                                    <Select<ColourOption>
                                        styles={{
                                            option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                                                const color = data.color;
                                                return {
                                                    ...styles,
                                                    backgroundColor: isDisabled
                                                        ? undefined
                                                        : isSelected
                                                            ? color + "40"
                                                            : isFocused
                                                                ? color + "30"
                                                                : undefined,
                                                    color: isDisabled
                                                        ? '#ccc'
                                                        : isSelected
                                                            ? 'black'
                                                            : color,
                                                    cursor: isDisabled ? 'not-allowed' : 'default',

                                                    ':active': {
                                                        ...styles[':active'],
                                                        backgroundColor: !isDisabled
                                                            ? isSelected
                                                                ? color + "50"
                                                                : color + "50"
                                                            : undefined,
                                                    }
                                                };
                                            },
                                            valueContainer:(style)=>{return {
                                                ...style,
                                                width: "10em",
                                                minHeight: "unset",
                                                lineHeight: "1em",
                                                padding: "0 0.5em",
                                                position: "relative",
                                            }}}}
                                        onChange={(v)=>{
                                            this.setMatch(i, v ? v.value : this.UNMATCHED_COL_VAL)
                                        }}
                                        value={
                                            (colMatch || colMatch === 0) ?
                                                ({value: "" + colMatch, label: availableFields[colMatch].title, color: "#80177e"} as ColourOption) :
                                                ({value: this.UNMATCHED_COL_VAL, label: "-", color: "#666666", unmatched: true} as ColourOption)
                                        }
                                        options={[
                                            {
                                                label: 'לא לייבא את העמודה הזו',
                                                value: this.UNMATCHED_COL_VAL,
                                                color: "#666666"
                                            },
                                            {
                                                label: 'שדות בסיסיים',
                                                options: availableFields.map((f, j)=>{
                                                    return {field: f, index: j}
                                                }).filter(fi=>fi.field.baseField).map((fi)=>{
                                                    return {value: "" + fi.index, label: fi.field.title, color: "#80177e"}
                                                }),
                                            },
                                            {
                                                label: 'שדות נוספים',
                                                options: availableFields.map((f, j)=>{
                                                    return {field: f, index: j}
                                                }).filter(fi=>!fi.field.baseField).map((fi)=>{
                                                    return {value: "" + fi.index, label: fi.field.title, color: "#80177e"}
                                                }),
                                            },
                                        ]}
                                    />
                                </div>
                                {
                                    (colMatch === null || colMatch === undefined) ?
                                        <span className={"no-match-warning"}>השדה לא ייקלט במערכת</span> :
                                        null
                                }
                            </div>
                        })}
                        <button
                            disabled={false}
                            type={"button"}
                            className={"big-button"}
                            onClick={async ()=>{
                                this.produceContacts();
                            }}
                        >
                            סיום ומעבר לתצוגה מקדימה
                        </button>
                    </div>
                    : emptyFile}
            </div>
        )
    }

}