import React from 'react';
import server from '../services/server';
import './Landing/Landing.scss';
import withRouter from "../services/withRouter"

const ErrorMessages = {
	NoFirstName: 'יש להזין שם פרטי يرجى اضافة الاسم للشخصي',
	NoLastName: 'יש להזין שם משפחה يرجى اضافة اسم العائلة',
	NoEmailOrPhone: `יש להזין מספר טלפון תקין או כתובת אימייל תקינה
يرجى اضافة رقم هاتف من صحيح او بريد الكتروني صحيح`,
	InvalidEmail: 'יש להזין כתובת אימייל תקינה يرجى اضافة بريد الكتروني صحيح',
	InvalidPhone: 'יש להזין מספר טלפון תקין يرجى اضافة رقم هاتف من صحيح',
	general: 'הייתה שגיאה במילוי הטופס, אנא בדקו את הפרטים ונסו שנית'
}

const FieldPatterns = {
	firstName: /^\D{2,}$/,
	lastName: /^\D{2,}$/,
	email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	// email: /^\D{2,}$/,
	phone: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{1,3})?[-. )]*(\d{2,3})[-. ]*(\d{1,4})(?:\s*(\d+))?[-. ]*(\d{2,4})\s*$/,
}

export default (withRouter(class Login extends React.Component {
	constructor(props) {
		super(props);
		const hashProp = this.props.searchParams.get("id");
		this.state = {
			hash: hashProp,
			activity: null,
			success: false
		};
		this.validateAndSendForm = this.validateAndSendForm.bind(this);
	}

	getEvent(hash) {
		return server.get(`./activities/landing-data/${hash}`)
		.then(res => {
			if (!res) return
			this.setState({ activity: res })
		})
	}

	componentDidMount() {
		this.getEvent(this.state.hash)
		// if(cookie.get('token')){
		// 	server.get("login/permissions", {ignoreErrors: true}).then((res)=>{
		// 		if(res.user_access && ! res.error){
		// 			this.props.saveUserRole(res.user_role)
		// 			if(this.state.redirect){
		// 				try{
		// 					this.props.navigate(decodeURIComponent(this.state.redirect));
		// 				}
		// 				catch(e){
		// 					console.log(e)
		// 				}
		// 			}
		// 			this.props.navigate('/Contacts');
		// 		}
		// 	})
		// }
	}

	validateAndSendForm(e) {
		this.setState({ errorKey: ''});
		if (!this.state.firstName) {
			this.setState({ errorKey: 'NoFirstName'});
			return
		}
		if (!FieldPatterns.firstName.test(this.state.firstName)) {
			this.setState({ errorKey: 'NoFirstName'});
			return
		}
		if (!this.state.lastName) {
			this.setState({ errorKey: 'NoLastName'});
			return
		}
		if (!FieldPatterns.lastName.test(this.state.lastName)) {
			this.setState({ errorKey: 'NoLastName'});
			return
		}
		if (!this.state.email && !this.state.phone) {
			this.setState({ errorKey: 'NoEmailOrPhone'});
			return
		}
		if (this.state.email && !FieldPatterns.email.test(this.state.email)) {
			this.setState({ errorKey: 'InvalidEmail'});
			return
		}
		if (this.state.phone && !FieldPatterns.phone.test(this.state.phone)) {
			this.setState({ errorKey: 'InvalidPhone'});
			return
		}
		
		this.setState({ isLoading: true })

		return server.post(`contacts/landing-page-form`, {
			hash: this.state.hash,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			email: this.state.email,
			phone: this.state.phone,
			residency: this.state.residency
		})
		.then(res => {
			if (res.error) throw res;
			console.log(res);
			if (!res.error) {
				this.setState({ errorKey: ''});
				this.setState({ isLoading: false });
			}
			this.setState({ success: true })
			document.querySelectorAll('.form input').forEach(elm => elm.value = '')
			setTimeout(() => {
				this.setState({ success: false })
			}, 5000);
		})
		.catch(err => {
			if (err.errorKey) this.setState({ errorKey: err.errorKey })
			else this.setState({ errorKey: 'general '})
		})
	}

	formInputChange(newState) {
		newState.errorMsg = '';
		this.setState(newState);
	}

	isErrorKey(errorKey, errorKey2) {
		return this.state.errorKey === errorKey || this.state.errorKey2 === errorKey
	}

	render() {
		if (this.state.activity) {
			return <div className="landing-page">
				<h1>{ this.state.activity.title }</h1>
				<p>
					עומדים ביחד היא תנועה שנאבקת למען עתיד בטוח בארץ, למען שיטה שסופרת את כולנו, למען חיים בכבוד, בצדק חברתי ובשלום. כי למרות שאנחנו באים ממקומות שונים, למרות שיש לנו זהויות שונות - האינטרסים שלנו משותפים. זה נכון לכולנו, ערבים ויהודים, וזה הופך אותנו לרוב. בעומדים ביחד אנחנו מתארגנים, בונים כוח, ובונים תנועה שתוביל את הדרך לשם. הצטרפו אלינו.
				</p>
				<div className="form">
					<label htmlFor="firstName">الاسم الشخصي שם פרטי
						<input type="text" id="firstName" name="firstName" 
							   className={ this.isErrorKey('NoFirstName')?'error':''} 
							   onChange={e => this.formInputChange({firstName: e.target.value})}>
						</input>
					</label>

					<label htmlFor="lastName">اسم العائلة שם משפחה
						<input type="text" id="lastName" name="lastName" 
							   className={ this.isErrorKey('NoLastName')?'error':''} 
						       onChange={e => this.formInputChange({lastName: e.target.value})}>
						</input>
					</label>

					<label htmlFor="email">البريد الإلكتروني כתובת דוא"ל
						<input type="text" id="email" name="email" 
							   className={ this.isErrorKey('NoEmailOrPhone', 'InvalidEmail')?'error':''} 
						       onChange={e => this.formInputChange({email: e.target.value})}>
						</input>
					</label>

					<label htmlFor="phone">رقم الهاتف טלפון
						<input type="text" id="phone" name="phone" 
							   className={ this.isErrorKey('NoEmailOrPhone', 'InvalidPhone')?'error':''} 
						       onChange={e => this.formInputChange({phone: e.target.value})}>
						</input>
					</label>

					<label htmlFor="residency">مكان الاقامة יישוב מגורים
						<input type="text" id="residency" name="residency" 
						       onChange={e => this.formInputChange({residency: e.target.value})}>
						</input>
					</label>

					<button onClick={this.validateAndSendForm}>
						{ this.state.isLoading? 
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
									<radialGradient id="a" cx=".7" cy=".3" fx=".7" fy=".3" gradientTransform="scale(2)">
										<stop offset="0" stopColor="#FFCF00"/>
										<stop offset=".3" stopColor="#FFCF00" stopOpacity=".9"/>
										<stop offset=".6" stopColor="#FFCF00" stopOpacity=".6"/>
										<stop offset=".8" stopColor="#FFCF00" stopOpacity=".3"/>
										<stop offset="1" stopColor="#FFCF00" stopOpacity="0"/>
									</radialGradient>
									<circle cx="100" cy="100" r="70" fill="none" stroke="url(#a)" strokeDasharray="200 1000" strokeLinecap="round" strokeWidth="15" transform-origin="center">
										<animateTransform attributeName="transform" calcMode="spline" dur="2" keySplines="0 0 1 1" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="360;0"/>
									</circle>
									<circle cx="100" cy="100" r="70" fill="none" stroke="#FFCF00" strokeLinecap="round" strokeWidth="15" opacity=".2" transform-origin="center"/>
								</svg>
						: 'שליחה ارسال'}
						</button>
				</div>
				<div className="error-msg">
					{ this.state.errorKey? ErrorMessages[this.state.errorKey]:'' }
				</div>
				{ (this.state.success)? <div className='success-msg'>
					תודה! הטופס נשלח בהצלחה
				</div>:null}
			</div>
		} else {
			return <div className="landing-page loading">
				Loading...
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
					<radialGradient id="a" cx=".7" cy=".3" fx=".7" fy=".3" gradientTransform="scale(2)">
						<stop offset="0" stopColor="#FFCF00"/>
						<stop offset=".3" stopColor="#FFCF00" stopOpacity=".9"/>
						<stop offset=".6" stopColor="#FFCF00" stopOpacity=".6"/>
						<stop offset=".8" stopColor="#FFCF00" stopOpacity=".3"/>
						<stop offset="1" stopColor="#FFCF00" stopOpacity="0"/>
					</radialGradient>
					<circle cx="100" cy="100" r="70" fill="none" stroke="url(#a)" strokeDasharray="200 1000" strokeLinecap="round" strokeWidth="15" transform-origin="center">
						<animateTransform attributeName="transform" calcMode="spline" dur="2" keySplines="0 0 1 1" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="360;0"/>
					</circle>
					<circle cx="100" cy="100" r="70" fill="none" stroke="#FFCF00" strokeLinecap="round" strokeWidth="15" opacity=".2" transform-origin="center"/>
				</svg>
			</div>
		}
	}
}))

